.background-header {
    object-fit: cover;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    z-index: -1;
    height: 100%;
}

.header {
    color:white;
    padding: 2rem;
    position:relative;
    min-height: 40rem;
    background: transparent linear-gradient(to bottom,rgba(0,0,0,0.4) 0px,rgba(0,0,0,0.65) 100%) repeat scroll 0% 0%;
}

h3, h4 {
    margin-top: 2rem;
}

.row{
    flex-wrap: wrap;
}

.section {
    min-width: 50%;
}