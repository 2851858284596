$color-primary: #e6852a !default;
$color-initial: #fff !default;
$color-secondary: #606c76 !default;
$color-tertiary: #f4f5f6 !default;
$color-quaternary: #d1d1d1 !default;
$color-quinary: #e1e1e1 !default;
@import "milligram/src/_Base";
@import "milligram/src/_Blockquote";
@import "milligram/src/_Button";
@import "milligram/src/_Code";
@import "milligram/src/_Divider";
@import "milligram/src/_Form";
@import "milligram/src/_Grid";
@import "milligram/src/_Link";
@import "milligram/src/_List";
@import "milligram/src/_Spacing";
@import "milligram/src/_Table";
@import "milligram/src/_Typography";
@import "milligram/src/_Image";
@import "milligram/src/_Utility";

body {
  font-family: 'Roboto Slab', serif;
}

.navigation {
  background: #f4f5f6;
  border-bottom: .1rem solid #d1d1d1;
  display: block;
  height: 5.2rem;
  left: 0;
  max-width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.navigation .navigation-list {
  list-style: none;
  margin-bottom: 0;
  margin-right: 5rem;
}

.navigation .navigation-item {
  float: left;
  margin-bottom: 0;
  margin-left: 2.5rem;
  position: relative;
}

.navigation .navigation-link, .navigation .navigation-title, .navigation .title {
  display: inline;
  font-size: 1.6rem;
  line-height: 5.2rem;
  padding: 0;
  text-decoration: none;
}

.wrapper>.container {
  padding-bottom: 7.5rem;
  padding-top: 7.5rem;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
}

.thumbnail {
  height: 100px;
  width: 100px;
  background-color: whitesmoke;
}

.grid-item{
  width: 150px;
  margin: 5px;
}

.navigation a{
  cursor: pointer;
}

.navigation .navigation-list {
  margin-right: 0 !important;
}